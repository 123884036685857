import './App.css';
import SuggestionLogo from "./suggestion.png"
import AkumuLogo from "./akumu.jpg"

function App() {

  const item = (name, img, alt, description, link) => {

    return (
      <div className='item'>
        <div className='item-inner'>
          <div className='img'>
            <img src={img} alt={alt}/>
          </div>
          <div className='info'>
            <h3>{name}</h3>
            <p>{description}</p>
          </div>
          <div className='invite'>
            <button onClick={() => {
              window.open(link, "_blank").focus()
            }}>Invite Now</button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="App">
      <div className='content'>
        <div>
          <h1>Public Bots</h1>
          <h2>Invite now</h2>
        </div>
        <div className='bots'>
          
          {item("Suggestions", SuggestionLogo, "Suggestions Bot", "Simple discord bot to manage suggestions for your Server", "https://discord.com/api/oauth2/authorize?client_id=984506416993808414&permissions=277025777728&scope=bot%20applications.commands")}
          {item("Akumu", AkumuLogo, "Akumu Bot", "Create Temp Channels and listen to music while chilling with your friends.", "https://discord.com/api/oauth2/authorize?client_id=1011726415353360404&permissions=398143351888&scope=bot%20applications.commands")}
      
        </div>
      </div>
      <footer>
        &copy; 2022 - <a href='https://axontica.com' target="_blank" rel="noreferrer">Axontica</a>
      </footer>
    </div>
  );
}

export default App;
